import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import LargeHeader from "../components/LargeHeader"
import LargeSection from "../components/LargeSection"
import YouTubeVideo from "../components/YouTubeVideo"
import { Youtube2, Facebook, Instagram, Spotify, World } from "../assets/icons"

export default function Home({ data }) {
  const { image1, frontendImage, imageaws } = data //backendImage, imageti

  return (
    <>
      <SEO
        title="About me"
        description="I have been building websites, web apps, APIs & web services for 15+ years professionally and a lot more years because it's cool. 😎"
        article={false}
      />
      <LargeHeader image={image1} alt="Thomas Zipner" className="h-full">
        <h1 className="giant">About me</h1>
        <h3 className="primary">
          I am a Full-Stack Developer & Solutions Architect from Germany.
        </h3>
        <p>
          I have been building <strong>web apps</strong>,{" "}
          <strong>websites</strong>, <strong>APIs</strong> &{" "}
          <strong>web services</strong> for <strong>15+ years</strong>{" "}
          professionally and many more years because it's cool. 😎
        </p>

        <p>
          I use <strong>JavaScript</strong>, <strong>Python</strong>,{" "}
          <strong>PHP</strong>, <strong>HTML</strong> & <strong>(S)CSS</strong>.
        </p>

        <p>
          Having used a wide range of technologies, I currently focus on{" "}
          <strong>Single Page Apps</strong> &{" "}
          <strong>Progressive Web Apps</strong> working with the{" "}
          <strong>React.js</strong> eco-system & <strong>GraphQL</strong> APIs
          (Apollo Server/ Hasura).
        </p>

        <p>
          I enjoy <strong>designing software solutions</strong> using{" "}
          <strong>AWS</strong> cloud services.
        </p>

        <p>
          For the last 8 years I have been working as{" "}
          <strong>Head of IT</strong> at{" "}
          <a href="https://ajando.com" target="_blank" rel="noreferrer">
            ajando
          </a>{" "}
          (as of 2021 Alex & Gross), building{" "}
          <strong>marketing & sales solutions</strong> and{" "}
          <strong>custom web apps</strong> for enterprise customers like Canon,
          SAP, unify or E.ON.
        </p>

        <p>
          I have spent a lot of time working with <strong>Wordpress</strong>,
          building <strong>themes</strong>, <strong>plugins</strong>,{" "}
          <strong>environments & tools</strong> to optimize development,
          deployment & hosting on AWS.
        </p>
      </LargeHeader>

      <LargeSection flip regSpace className="diagonal-section">
        <div className="row d-flex align-items-lg-center">
          <div className="col-12 col-md-3 d-none d-md-block">
            <Img
              fluid={frontendImage.childImageSharp.fluid}
              alt="Smart Phone"
              style={{
                width: "100%",
              }}
            />
          </div>

          <div className="col-12 col-md-9 col-lg-7 offset-lg-1">
            <h2 className="mb0">Frontend</h2>
            <h3 className="primary h4 mt0">React.js, SPA, PWA, Wordpress</h3>
            <p>
              I am a fan of the <strong>React.js</strong> eco system and use it
              for everything from simple website modules, quick{" "}
              <strong>MVP</strong>s to complex <strong>Single Page Apps</strong>
              .
            </p>

            <p>
              I am familiar with <strong>JEST</strong> &{" "}
              <strong>React Testing Library</strong>, as well as{" "}
              <strong>Cypress</strong> for E2E-testing.
            </p>
            <p>
              I have worked with <strong>Bootstrap</strong>,{" "}
              <strong>Tailwind CSS</strong>, <strong>Bulma</strong> &{" "}
              <strong>Material UI</strong>. I mostly prefer the{" "}
              <strong>BEM</strong> conventions over a thousand utility classes &
              like <strong>styled components</strong> in JavaScript Apps.
            </p>

            <p>
              I use <strong>webpack</strong> & <strong>Gulp.js</strong> to
              optimize, build & bundle code & assets.
            </p>

            <p>
              I have quite a history with <strong>Wordpress</strong>, building
              custom Themes, Plugins & development environments.
            </p>
          </div>
        </div>
      </LargeSection>

      <LargeSection
        regSpace
        className="diagonal-section diagonal-section--transparent"
      >
        <div className="row flex justify-content-center">
          {/* <div className="col-12 col-md-3">
        <Img
              fluid={backendImage.childImageSharp.fluid}
              alt="GraphQL"
              style={{
                width: "100%",
              }}
            />
          </div>
        */}
          <div className="col-12 col-lg-8 offset-md-1">
            <h2 className="mb0">Backend</h2>
            <h3 className="primary h4 mt0">
              GraphQL, REST, JavaScript, Python, PHP
            </h3>
            <p>
              I have worked with a variety of different technology stacks, but
              most of the time it boils down to a selection of:
            </p>

            <p>
              <span className="d-block d-md-inline-block font-weight-bold text-white">
                GraphQL-API:
              </span>{" "}
              Hasura GraphQL engine, Apollo Server, Graphene-Python
              <br />
              <span className="d-block d-md-inline-block font-weight-bold mt-2 text-white">
                REST-API:
              </span>{" "}
              Flask RESTful, SQLAlchemy, Marshmallow, Express.js
              <br />
              <span className="d-block d-md-inline-block font-weight-bold mt-2 text-white">
                Databases:
              </span>{" "}
              MySQL/ MariaDB, PostgreSQL, MongoDB, DynamoDB
              <br />
              <span className="d-block d-md-inline-block font-weight-bold mt-2 text-white">
                File storage:
              </span>{" "}
              S3
              <br />
              <span className="d-block d-md-inline-block font-weight-bold mt-2 text-white">
                Features, Services & Jobs:
              </span>{" "}
              AWS Lambda functions (Node.js/ Python)
              <br />
              <span className="d-block d-md-inline-block font-weight-bold mt-2 text-white">
                Decoupling:
              </span>{" "}
              Amazon MQ (Apache ActiveMQ), SQS, SNS
              <br />
              <span className="d-block d-md-inline-block font-weight-bold mt-2 text-white">
                Multi purpose:
              </span>{" "}
              Django, Wordpress
              <br />
            </p>

            <p>
              I try to embrace <strong>serverless technologies</strong> &{" "}
              <strong>cloud services</strong> unless there is a good reason not
              to.
            </p>

            <p>
              For more traditional web-hosting stacks I have worked a lot with{" "}
              <strong>nginx</strong> as a web-server/ reverse proxy, but it
              seems to become a seldom case.
            </p>
          </div>
        </div>
      </LargeSection>

      <LargeSection
        image={imageaws}
        flip
        regSpace
        className="diagonal-section diagonal-section--flip"
      >
        <div className="row">
          <div className="col-12 col-md-3 d-none d-md-block">
            <Img
              fluid={imageaws.childImageSharp.fluid}
              alt="AWS Logo"
              style={{
                width: "100%",
              }}
            />
          </div>
          <div className="col-12 col-md-9 col-lg-8">
            <h2 id="aws" className="mb0">
              Cloud & AWS
            </h2>
            <h3 className="primary h4 mt0">
              Architecture & a lot of 3-letter services
            </h3>

            <p>
              <span className="d-block d-md-inline-block font-weight-bold mt-3 text-white">
                5+ years of experience
              </span>{" "}
              in designing scalable, resilient & cost effective web services on
              AWS
              <br />
              <span className="d-block d-md-inline-block font-weight-bold mt-3 text-white">
                Hands on experience with
              </span>{" "}
              EC2, ECS, Lambda, ELB/ALB, VPC, RDS, DynamoDB, S3, CloudFront,
              IAM, SQS, SNS, Amazon MQ, Cloud Watch, Amplify, Cognito,
              ElastiCache, Route 53
              <br />
              <span className="d-block d-md-inline-block font-weight-bold mt-3 text-white">
                CI/CD Tools:
              </span>{" "}
              CodePipeline, CodeBuild, CodeDeploy, Serverless Framework,
              Terraform, Ansible
              <br />
              <span className="d-block d-md-inline-block font-weight-bold mt-3 text-white">
                Containerization:
              </span>{" "}
              Docker
              <br />
            </p>
          </div>
        </div>
      </LargeSection>

      <LargeSection
        flip
        regSpace
        className="diagonal-section diagonal-section--transparent"
      >
        <h2 className="mb0">What drives me?</h2>
        <h3 className="primary h4 mt0">
          Solving real-world problems with technology
        </h3>
        <div className="row">
          <div className="col-12 col-lg-8">
            <p>
              Although technology is fun for its own sake, I really enjoy to{" "}
              <strong>
                explore the mechanics & challenges of different markets &
                industries
              </strong>
              . 10 years back I would have never thought that I knew something
              about the expansion of fibre optic networks in rural areas or
              about precision farming. I am very grateful that I can work on
              different projects that allow me dive into all these completely
              different fields.
            </p>
            <p>
              And of course I enjoy the{" "}
              <strong>spirit of all the great people</strong> I have the
              pleasure to work with, who bring projects to life with{" "}
              <strong>entrepreneurial thinking</strong>, coffee and stupid
              jokes. ☕️😜
            </p>
          </div>
        </div>
      </LargeSection>

      <LargeSection regSpace className="diagonal-section">
        <div className="row d-flex align-items-center mb-5 justify-content-center">
          <div className="col-12 col-lg-8">
            <h2 className="mb0">When I am not in front of a computer...</h2>
            <h3 className="primary h4 mt0 pb-3">
              The Intersphere, Surfing, Outdoor sports
            </h3>
            <p>
              I play guitar in a band called <strong>The Intersphere</strong>.
              This has been a huge part of my life since 2006.
              <br />
              Go check them out on Spotify or one of our channels or hit the
              play button.
            </p>
            <ul className="ul-social justify-content-center mb-2">
              <li>
                <a
                  href="https://open.spotify.com/artist/7GGtuvcBdHQYVbXi9B8YnY"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Spotify />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/user/theintersphere"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Youtube2 />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/theintersphere_official/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/theintersphere/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
              </li>
              <li>
                <a
                  href="https://theintersphere.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <World />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-8">
            <YouTubeVideo videoId="ilroyyHZtcE" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <p>
              I love <strong>surfing</strong>
              <span role="img" aria-label="Surfer Emoji">
                🏄‍♂️,
              </span>{" "}
              but since I live in Germany I cannot go too often. I like to go{" "}
              <strong>jogging</strong>, <strong>hiking</strong> and things that
              feel like the opposite of sitting as often as I can.
            </p>
            <p>
              I enjoy Raspberry Pi projects that involve hardware like sensors &
              engines &{" "}
              <span role="img" aria-label="Robot Emoji">
                🤖
              </span>{" "}
              robotics stuff.{" "}
            </p>
            <p>
              I am a fan of <strong>Southpark</strong> and think I have a
              Southpark reference for most situations in life.{" "}
              <span role="img" aria-label="Laughing tears emoji">
                😂
              </span>
            </p>
          </div>
        </div>
      </LargeSection>
    </>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "thommy-1200.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1250) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    frontendImage: file(relativePath: { eq: "spa.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 699) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    backendImage: file(relativePath: { eq: "graphql.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 699) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    imageaws: file(relativePath: { eq: "aws.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    imageti: file(relativePath: { eq: "theintersphere-hd.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
